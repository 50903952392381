.notification {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 5;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  height: 5rem;
  top: 40%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.notification h2 {
  font-size: 6;
  margin: 0;
}

.success {
  background-color: #1e478a;
  color: white;
}

.error {
  background-color: #ff4747;
  color: white;
}

.transition {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.hidden {
  visibility: hidden;
}

@media (min-width: 768px) {
  .notification {
    width: 20rem;
    left: calc(50% - 10rem);
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
}
