.delete-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 10rem;
  top: 40%;
  background-color: #eef2f9;
  color: rgb(67, 65, 65);
  border: 1px solid #495b7a;
}

.delete-popup h2 {
  text-align: center;
  font-size: 1.2rem;
  margin: 0;
}

.btn-container {
  justify-content: center;
  margin: 0;
}

.delete-popup .btn {
  margin: 0 2rem;
  width: 10rem;
}

@media (min-width: 768px) {
  .delete-popup {
    width: 20rem;
    left: calc(50% - 10rem);
  }
}
